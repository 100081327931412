import React, { useState, useEffect,useRef,useLayoutEffect  } from 'react';
import './css/ChatPanel.css';
import { Translator, resources } from './Translator';
import { CommonUtils } from './CommonUtils';

const Message = ({ message, fm }) => {
	const [iconSrc, setIconSrc] = useState(null);

	useEffect(() => {
		setTimeout(() => {
			const loadIcon = async () => {
				const iconData = await fm.getIconData(message.owneruid, message.ownername, message.ownercolor);
				setIconSrc(iconData);
			};
			loadIcon();
		}, Math.floor(Math.random() * (600 - 300 + 1)) + 300);//待たないとアイコンが読み込まれないので乱数でばらつかさせながら待つ

	}, [message.owneruid, message.ownername, message.ownercolor, fm,iconSrc]);

	return (
		<div className="message">
		<div className="message-header">
			<img className="userIcon" alt={`${message.ownername}'s icon`} src={iconSrc || '/img/default_icon.png'} />
			<div className="message-info">
				<strong style={{color: message.ownercolor}}>{message.ownername}</strong>
				<span className="timestamp">{message.timestamp}</span>
			</div>
		</div>
		<p className="message-content">{message.content}</p>
		</div>
	);
};

const ChatPanel = ({fm, uuid, owneruid, myUid, userDoc, isOpen, onClose }) => {
	const [messages, setMessages] = useState([]);
	const [inputMessage, setInputMessage] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [oldestTimestamp, setOldestTimestamp] = useState(null);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

	const translator = new Translator(resources);
	const messagesEndRef = useRef(null);
	const chatMessagesRef = useRef(null);

	const t = (key) => translator.t(key);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		if(!owneruid || !fm || !uuid) return;

		const collectionPath = `userdata/${owneruid}/projects/${uuid}/chat`;
		const unsubscribe = fm.subscribeToCollectionWithOption(
			collectionPath,
			{ limit: 10, orderBy: 'updatetime', orderDirection: 'desc' },
			(snapshot) => {
				const newMessages = snapshot.docs.map(doc => ({
					id: doc.id,
					ownername: doc.data().ownername,
					ownercolor: doc.data().ownercolor,
					content: doc.data().content,
					timestamp: doc.data().updatetime ? doc.data().updatetime.toDate().toLocaleString() : '',
					updatetime: doc.data().updatetime,
					...doc.data()
				})).reverse();  // Reverse to get ascending order

				setMessages(newMessages);
				if (newMessages.length > 0) {
					setOldestTimestamp(newMessages[0].updatetime);
				}
				setTimeout(() => {
					scrollToBottom();
					setShouldScrollToBottom(true);
				}, 200);
			}
		);

		return () => unsubscribe();
	}, [fm, owneruid, uuid]);

	useLayoutEffect(() => {
		if (shouldScrollToBottom) {
			scrollToBottom();
			setShouldScrollToBottom(false);
		}
	}, [shouldScrollToBottom]);

	const loadMoreMessages = async () => {
		if (isLoadingMore || !oldestTimestamp) return;

		setIsLoadingMore(true);
		const collectionPath = `userdata/${owneruid}/projects/${uuid}/chat`;
		try {

			const snapshot = await fm.getMoreDocuments(
				collectionPath,
				{
					limit: 10, 
					orderBy: 'updatetime', 
					orderDirection: 'desc', 
					endBefore: oldestTimestamp 
				}
			);
			snapshot.docs.map(async (doc) => {
				console.log(doc.data().content + " : " + doc.data().updatetime.toDate().toLocaleString());
			});

			const moreMessages = snapshot.docs.map(doc => ({
				id: doc.id,
				ownername: doc.data().ownername,
				ownercolor: doc.data().ownercolor,
				content: doc.data().content,
				timestamp: doc.data().updatetime ? doc.data().updatetime.toDate().toLocaleString() : '',
				updatetime: doc.data().updatetime,
				...doc.data()
			})).reverse();  // Reverse to get ascending order
			
			if (moreMessages.length > 0) {
				setMessages(prevMessages => [...moreMessages, ...prevMessages]);
				setOldestTimestamp(moreMessages[0].updatetime);
			}
		} catch (error) {
			console.error('Error loading more messages:', error);
		} finally {
			setIsLoadingMore(false);
		}
	};

	const handleScroll = () => {
		if (chatMessagesRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = chatMessagesRef.current;
			if (scrollTop === 0) {
				loadMoreMessages().then(() => {
					// After loading more messages, adjust the scroll position
					if (chatMessagesRef.current) {
						const newScrollHeight = chatMessagesRef.current.scrollHeight;
						const scrollAdjustment = newScrollHeight - scrollHeight;
						chatMessagesRef.current.scrollTop = scrollAdjustment + 1; // Scroll down by 1 pixel
					}
				});
			}
		}
	};

	const handleSendMessage = async (e) => {
		e.preventDefault();
		if (!inputMessage.trim()) return;

		const documentId = CommonUtils.generateUUID();
		setIsSubmitting(true);
		const collectionPath = `userdata/${owneruid}/projects/${uuid}/chat`;

		const firedata = {
			content: inputMessage,
			ownername: userDoc.name,
			ownercolor: userDoc.color,
			owneruid: myUid,
			createtime: new Date(),
			updatetime: new Date(),
		};

		try {
			await fm.setDocument(collectionPath, documentId, firedata);
			console.log('Document successfully written!');
			setInputMessage('');
			scrollToBottom();
		} catch (error) {
			console.error('Failed to write document:', error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className={`chat-panel ${isOpen ? 'open' : ''}`} style={{zIndex:101}}>
		<h2>{t('chat')}</h2>
		<button className="chat_buttonN" onClick={onClose}>{t('Close')}</button>
		<div className="chat-messages" ref={chatMessagesRef} onScroll={handleScroll}>
			{messages.map(message => (
			<Message key={message.id} message={message} fm={fm} />
			))}
			<div ref={messagesEndRef} />
		</div>
		{fm.auth.currentUser && 

			<form onSubmit={handleSendMessage} className="chat-input">
				<input
				type="text"
				value={inputMessage}
				onChange={(e) => setInputMessage(e.target.value)}
				placeholder={t('Type a comment...')}
				/>
				<button type="submit" disabled={isSubmitting}>
				{isSubmitting ? t('Processing...') : t('Send')}
				</button>
			</form>
		}
		</div>
	);
};

export default ChatPanel;