import React, { useState,useEffect } from 'react';
import './css/popup.css';
import MarkerSelectPopup from './MarkerSelectPopup';
import { Translator, resources } from './Translator';
import { CommonUtils} from './CommonUtils';
import { MARKER_ARRAY } from './constants';
import elevationService from './ElevationService';

const translator = new Translator(resources);

const EditMarkerPopup = ({ onSubmit, onClose,markerData}) => {
	const t = (key) => translator.t(key);
	const [name, setName] = useState(markerData.name);
	const [elevation, setElevation] = useState(markerData.elevation);
	const [note, setNote] = useState(markerData.note);
	const [showMarkerSelect, setShowMarkerSelect] = useState(false);
	const [iconSrc, setIconSrc] = useState(MARKER_ARRAY[markerData.iconNumber]);
	const [iconNumber, setIconNumber] = useState(markerData.iconNumber);
	const [marker, setmarkerData] = useState(markerData);


	const handleSubmit = () => {
		onSubmit({ name, elevation, note, iconSrc, iconNumber,marker });
		onClose();
	};

	const handleIconClick = () => {
		setShowMarkerSelect(true);
	};

	const handleIconSelect = ({ iconNumber, iconSrc }) => {
		setIconSrc(iconSrc);
		setIconNumber(iconNumber);
		setShowMarkerSelect(false);
	};

	return (
		<div className="popup_opaque" style={{ minWidth: '250px',maxWidth: '500px' }}>
			<span className="close_button" onClick={onClose}>&times;</span>
			<div className="popup-inner">
				<h2>{t('edit')}</h2>
				<label>
					{t('Marker name')}:
					<input type="text" value={name} onChange={(e) => setName(e.target.value)} />
				</label>
				<label>
					{t('Elevation')}:
					<input type="text" value={elevation} onChange={(e) => setElevation(e.target.value)} />
				</label>
				<label id="marker_memo">
					{t('Note')}:
					<textarea value={note} onChange={(e) => setNote(e.target.value)} />
				</label>
				<div className="rowBox" >
				<label id="iconLabel" className="popup-inner-label-nowrap">{t('Icon')}</label>
				<img className="selectedIcon" src={iconSrc} alt="Selected Icon" onClick={handleIconClick}></img>
				</div>
				<button className='button_b' onClick={onClose}>{t('Cancel')}</button>
				<button className='button_y' onClick={handleSubmit}>{t('Save')}</button>
			</div>
			{showMarkerSelect && (
				<MarkerSelectPopup onSubmit={handleIconSelect} onClose={() => setShowMarkerSelect(false)} />
			)}
			<style jsx="true">{`
				.popup,.popup_opaque {
					top: calc(50% + 20px) !important;
				}
			`}</style>
		</div>
	);
};

export default EditMarkerPopup;
