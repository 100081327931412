import React from 'react';
import './css/ProgressBar.css';
import { Translator, resources } from './Translator';

const translator = new Translator(resources);
const t = (key) => translator.t(key);

const ProgressBar = ({ progress, total }) => {
	const percentage = Math.round((progress / total) * 100);

	return (
		<div className="progress-bar-container">
		<div className="progress-bar" style={{ width: `${percentage}%` }}>
			{percentage}%
		</div>
		<div className="progress-text">
			{t('Uploading...')} {progress} / {total}
		</div>
		</div>
	);
};

export default ProgressBar;
