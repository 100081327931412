import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonUtils } from './CommonUtils';
import { Translator, resources } from './Translator';


const translator = new Translator(resources);

const ProjectList = ({ items }) => {
	const navigate = useNavigate();

	const t = (key) => translator.t(key);
	const handleItemClick = (project) => {
		navigate(`/map/${project.id}_${project.owneruid}#14/${project.lat}/${project.lng}`);
	};


	return (
		<div className="project-list">
			<ul>
				{items.map(project => {
					let updatetime = null;
					if(project.updatetime == null || project.updatetime === undefined) updatetime = new Date();
					else updatetime = project.updatetime.toDate();

					const timeString = CommonUtils.formatDateWithIsSec(updatetime);
					const tagsString = project.tags ? project.tags.map(tag => `#${tag}`).join(' ') : '';
					return (
						<li key={project.id} onClick={() => handleItemClick(project)}>
							<div className="project-info">
								<span className="projectList_title">{project.title}</span> : {project.ownername}
								{project.memo && <span className="project-memo"> / {project.memo}</span>}
							</div>
							<div className="project-details">
								<span className="project-time">{timeString}</span>
								{tagsString && <span className="project-tags">{tagsString}</span>}
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default ProjectList;