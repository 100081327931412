import React, { useState, useEffect } from 'react';
import './css/popup.css';
import { Translator, resources } from './Translator';

const translator = new Translator(resources);

const SearchPopup = ({ onSubmit, onClose, onUpdate, initialKeyword, initialResults }) => {
    const t = (key) => translator.t(key);
    const [keyword, setKeyword] = useState(initialKeyword);
    const [results, setResults] = useState(initialResults);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

	useEffect(() => {
		// コンポーネントがアンマウントされるときに最新の状態を親に通知
		return () => {
			onUpdate(keyword, results);
		};
	}, [keyword, results, onUpdate]);

	const handleSearch = async () => {
		if (!keyword.trim()) return;

		setIsLoading(true);
		setError(null);

		try {
			const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(keyword)}&format=json&addressdetails=1&limit=10`;
			const response = await fetch(url);
			const data = await response.json();

			const formattedResults = data.map(place => ({
				name: place.display_name,
				lat: parseFloat(place.lat),
				lng: parseFloat(place.lon)
			}));

			setResults(formattedResults);
			onUpdate(keyword, formattedResults);
		} catch (error) {
			console.error("Error fetching data from Nominatim API:", error);
			setError(t('Search failed. Please try again.'));
		} finally {
			setIsLoading(false);
		}
	};
    const handleResultClick = (result) => {
        onSubmit(result);
    };

    return (
        <div className="popup_opaque" style={{ minWidth: '250px', maxWidth: '500px' }}>
            <span className="close_button" onClick={onClose}>&times;</span>
            <div className="popup-inner">
                <h2>{t('Search Location')}</h2>
                <div className="search-box">
					<input 
						type="search"
						enterkeyhint="search"
						value={keyword} 
						onChange={(e) => setKeyword(e.target.value)} 
						placeholder={t('Enter location')}
						onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
					/>
                </div>
                {error && <div className="error-message">{error}</div>}
                <div className="search-results">
                    {results.map((result, index) => (
                        <div key={index} className="search-result-item" onClick={() => handleResultClick(result)}>
                            {result.name}
                        </div>
                    ))}
                </div>
            </div>

            <style jsx>{`
                .search-box {
                    display: flex;
                    margin-bottom: 15px;
                }

                .search-box input {
                    flex-grow: 1;
                    margin-right: 10px;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                }

                .search-results {
                    max-height: 300px;
                    overflow-y: auto;
                    border: 1px solid #e0e0e0;
                    border-radius: 4px;
                }

                .search-result-item {
                    padding: 10px;
                    border-bottom: 1px solid #e0e0e0;
                    cursor: pointer;
                    transition: background-color 0.2s;
                }

                .search-result-item:last-child {
                    border-bottom: none;
                }

                .search-result-item:hover {
                    background-color: #369;
                }

                .error-message {
                    color: red;
                    margin-bottom: 10px;
                }
            `}</style>
        </div>
    );
};

export default SearchPopup;