import React, { useState, useEffect } from 'react';
import './css/popup.css';
import { Translator, resources } from './Translator';
import QRCode from 'qrcode.react';
import { CommonUtils } from './CommonUtils';

const translator = new Translator(resources);

const MapSharePopup = ({ onClose,currentProject }) => {
    const t = (key) => translator.t(key);
    const [currentUrl, setCurrentUrl] = useState('');
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
		const url = window.location.href;
		const replacedUrl = url.replace('http://localhost:3000/', 'https://collabomap.com/');
		setCurrentUrl(replacedUrl);
    }, []);

    const handleCopyUrl = () => {
        if(!currentProject) return;
        let hashtags = "";
        if(currentProject.tags && currentProject.tags.length > 0) hashtags = currentProject.tags.map(word => `#${word}`).join(' ');
        let copyText = `[${currentProject.title} : ${currentProject.memo} ${hashtags}]\n${currentUrl}`;

        navigator.clipboard.writeText(copyText).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        });
    };

    return (
        <div className="popup">
            <span className="close_button" onClick={onClose}>&times;</span>
            <div className="popup-inner">
                <h2>{t('Share Map')}</h2>
                <div className="url-container">
                    <input type="text" value={currentUrl} readOnly />
                    <button className='button_b' onClick={handleCopyUrl}>
                        {isCopied ? t('Copied!') : t('Copy URL')}
                    </button>
                </div>
                <div className="qr-code-container" style={{
                    backgroundColor: 'white',
                    padding: '20px',
					borderRadius:'10px',
                    display: 'inline-block',
                    margin: '20px 0'
                }}>
                    <QRCode value={currentUrl} size={160} />
                </div><p></p>
                <button className='button_y' onClick={onClose}>{t('Close')}</button>
            </div>
        </div>
    );
};

export default MapSharePopup;