// src/Marker.js
import maplibregl from 'maplibre-gl';
import { Translator, resources } from './Translator';
import { CommonUtils} from './CommonUtils';
import { MARKER_ARRAY } from './constants';
import elevationService from './ElevationService';

class Marker {

	static _markerColors = ['#3c148c','#1673ad','#18932c','#6c4303','#931c26','#000000'];
	translator = new Translator(resources);
	t = (key) => this.translator.t(key);

	constructor(map, lng, lat, elevation, data, size, docId, boss,callback,moveCallback,clickCallback) {
		this.makeMarker(map, lng, lat, elevation, data, size, docId, boss,callback,moveCallback,clickCallback);
	}

	remakeMarker(callback,moveCallback,clickCallback) {
		this.makeMarker(this.map, this.lng, this.lat, this.elevation, this.data, this.size, this.docId, this.boss,callback,moveCallback,clickCallback)
	}

	update(data) {
		this.lat = data.lat;
		this.lng = data.lng;
		this.elevation = data.elevation;
		this.name = data.name;
		this.note = data.note;
		this.iconNumber = data.iconNumber;

		this.data.lat = data.lat;
		this.data.lng = data.lng;
		this.data.elevation = data.elevation;
		this.data.name = data.name;
		this.data.note = data.note;
		this.data.iconNumber = data.iconNumber;
		this.data.updatetime = data.updatetime;

		// マーカーの位置を更新
		if (this.marker) {
			this.marker.setLngLat([this.lng, this.lat]);
		}
	}

	makeMarker(map, lng, lat, elevation, data, size, docId, boss,callback,moveCallback,clickCallback) {
		this.map = map;
		this.lat = lat;
		this.lng = lng;
		this.elevation = elevation;
		this.name = data.name;
		this.note = data.note;
		this.size = size;
		this.iconNumber = data.iconNumber;
		this.docId = docId;
		this.boss = boss;
		this.data = data;
		this.popup = null;
		this.callback = callback;
		this.moveCallback = moveCallback;
		this.clickCallback  = clickCallback ;
		this.currentLocation = null;

		this.distance = 0;

		if (data.iconDataUrl) {
			// 写真マーカーの場合
			const iconImage = new Image();
			iconImage.src = data.iconDataUrl;
			iconImage.onload = () => {
				const resizedIconImage = new Image();
				resizedIconImage.src = this.makePhotoIconImage(iconImage,data.note);
				resizedIconImage.onload = () => {
					this.marker = new maplibregl.Marker({ element: resizedIconImage, offset: [0, 0] })
						.setLngLat([this.lng, this.lat]);

					this.marker.getElement().addEventListener('click', (e) => {
						if (this.clickCallback) {
							this.clickCallback(this);
						}
					});
					this.marker.getElement().style.zIndex = '1';
					// ドラッグイベントリスナーの追加
					this.marker.on('dragend', () => {
						const lngLat = this.marker.getLngLat();
						this.lng = lngLat.lng;
						this.lat = lngLat.lat;
						this.stopMoving(); // ドラッグ後に明滅を停止
						const fetchElevation = async () => {
							const elevationValue = await elevationService.getElevation(this.lat, this.lng);
							if (elevationValue !== null) {
								this.elevation = elevationValue;
							}
							if (typeof callback === 'function') {
								if(moveCallback) moveCallback(this);
							}
						};
						fetchElevation();
					});

					this.addToMap();
					if (typeof callback === 'function') {
						callback(this);
					}
				};
			};
		}
		else {
			this.makeIconImageWithNumber(this.iconNumber, data.name,  data.note ? data.note : data.elevation + 'm', size, (dataURL) => {
				const iconImage = new Image();
				iconImage.src = dataURL;
				iconImage.onload = () => {
					if(boss.props.fm.auth.currentUser && boss.props.fm.auth.currentUser.uid === this.data.owneruid) {
						this.popup = new maplibregl.Popup()
							.setHTML(`<h3>${this.name}</h3><h4> ${this.note}</h4><p></p><p>${this.t('Elevation')}: ${this.elevation}m</p><br><button class="buttonBlue_s" id="markerEditButton" data-id="">${this.t('edit')}</button><button class="buttonBlue_s" id="markerMoveButton" data-id="">${this.t('move')}</button><button class="buttonBlue_s" id="markerCopyButton" data-id="">${this.t('copy')}</button><button class="buttonBlue_s" id="markerCopyButtonB" data-id="">${this.t('copyB')}</button><br><button class="buttonRed_s" id="markerRemoveButton" data-id="">${this.t('delete')}</button>`);
					}
					else {
						this.popup = new maplibregl.Popup()
							.setHTML(`<h3>${this.name}</h3><h4> ${this.note}</h4><p></p><p>${this.t('Elevation')}: ${this.elevation}m</p>`);
					}

					let offset = 0;
					if(this.iconNumber >= 6 && this.iconNumber <= 11) offset = -18;
					if(this.marker) {
						this.marker.remove();
						this.marker = null;
					}
					this.marker = new maplibregl.Marker({ element: iconImage,offset: [0, offset]})
						.setLngLat([this.lng, this.lat])
						.setPopup(this.popup);
					// ドラッグイベントリスナーの追加
					this.marker.on('dragend', () => {
						const lngLat = this.marker.getLngLat();
						this.lng = lngLat.lng;
						this.lat = lngLat.lat;
						this.stopMoving(); // ドラッグ後に明滅を停止
						const fetchElevation = async () => {
							const elevationValue = await elevationService.getElevation(this.lat, this.lng);
							if (elevationValue !== null) {
								this.elevation = elevationValue;
							}
							if (typeof callback === 'function') {
								if(moveCallback) moveCallback(this);
							}
						};
						fetchElevation();
					});
					this.marker.getElement().style.zIndex = '2';

					this.marker.getElement().addEventListener('click', (e) => {
						if (this.clickCallback) {
							this.clickCallback(this);
						}
						boss.currentMarker = this;
						console.log("marker:" + boss.currentMarker.name);
						if(boss.props.fm.auth.currentUser && boss.props.fm.auth.currentUser.uid === this.data.owneruid) {
							this.popup = new maplibregl.Popup()
								.setHTML(`<h3>${this.name}</h3><h4> ${this.note}</h4><p></p><p>${this.t('Elevation')}: ${this.elevation}m</p><button class="buttonBlue_s" id="markerEditButton" data-id="">${this.t('edit')}</button><button class="buttonBlue_s" id="markerMoveButton" data-id="">${this.t('move')}</button><button class="buttonBlue_s" id="markerCopyButton" data-id="">${this.t('copy')}</button><button class="buttonBlue_s" id="markerCopyButtonB" data-id="">${this.t('copyB')}</button><br><button class="buttonRed_s" id="markerRemoveButton" data-id="">${this.t('delete')}</button>`);
//								.setHTML(`<h3>${this.name}</h3><h4> ${this.note}</h4><p></p><p>${this.t('Elevation')}: ${this.elevation}m</p>${this.distance}m<br><button class="buttonBlue_s" id="markerEditButton" data-id="">${this.t('edit')}</button><button class="buttonBlue_s" id="markerMoveButton" data-id="">${this.t('move')}</button><button class="buttonRed_s" id="markerRemoveButton" data-id="">${this.t('delete')}</button>`);
						}
						else {
							this.popup = new maplibregl.Popup()
								.setHTML(`<h3>${this.name}</h3><h4> ${this.note}</h4><p></p><p>${this.t('Elevation')}: ${this.elevation}m</p>`);
//								.setHTML(`<h3>${this.name}</h3><h4> ${this.note}</h4><p></p><p>${this.t('Elevation')}: ${this.elevation}m</p>${this.distance}m `);
						}
						this.marker.setPopup(this.popup);
						this.setButtonEvents();

						this.marker.setDraggable(false);
						this.stopMoving();
					});

					this.setButtonEvents();
					// コールバックを呼び出す
					if (typeof callback === 'function') {
						if(callback) callback(this);
					}				
				};
				iconImage.onerror = function (error) {
					console.error("Image failed to load", error);
				};
			});
		}
	}

	async copyToClipboard() {
		const markerData = {
			app:'CollaboMap',
			lat: this.lat,
			lng: this.lng,
			elevation: this.elevation,
			name: this.name,
			note: this.note,
			iconNumber: this.iconNumber
		};

		const jsonString = JSON.stringify(markerData, null, 2);

		try {
			await navigator.clipboard.writeText(jsonString);
			console.log('Marker data copied to clipboard');
			this.boss.markerCopyToClipboard(this);
		} catch (err) {
			console.error('Failed to copy marker data: ', err);
			// ここでエラーメッセージを表示するなどの処理を追加できます
		}
	}

	makePhotoIconImage(iconImage,note) {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		canvas.width = 60;
		canvas.height = 60;
		
		// 画像を48x48にリサイズして描画
		ctx.drawImage(iconImage, 6, 6, 48, 48);

		// データにnoteがある場合、テキストを描画
		if (note) {
			let noteText = note.length > 5 ? note.slice(0, 5) : note;
			
			// フォントサイズを計算
			let fontSize = 12; // デフォルトのフォントサイズ
			ctx.font = `${fontSize}px Arial`;
			let textWidth = ctx.measureText(noteText).width;
			
			// テキストが幅に収まるようにフォントサイズを調整
			while (textWidth > 70 && fontSize > 30) {
				fontSize--;
				ctx.font = `${fontSize}px Arial`;
				textWidth = ctx.measureText(noteText).width;
			}

			// テキストを描画
			ctx.fillStyle = 'white';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'top';
			ctx.strokeStyle = 'black';
			ctx.lineWidth = 4;
			ctx.strokeText(noteText, canvas.width / 2, 4);
			ctx.fillText(noteText, canvas.width / 2, 4);
		}					
		return canvas.toDataURL();
	}

	updatePhotoIcon(newNote) {
		if (this.data.iconDataUrl) {
			const iconImage = new Image();
			iconImage.src = this.data.iconDataUrl;
			iconImage.onload = () => {
				const newIconDataUrl = this.makePhotoIconImage(iconImage, newNote);
				const newIconElement = new Image();
				newIconElement.src = newIconDataUrl;
				newIconElement.onload = () => {
					// マーカーの要素を新しいアイコンで更新
					this.marker.getElement().src = newIconDataUrl;
				};
			};
		}
	}


	// ボタンクリックイベントの設定
	setButtonEvents() {
		if(!this.boss.props.fm.auth.currentUser || this.boss.props.fm.auth.currentUser.uid !== this.data.owneruid) return;

		// ボタンクリックイベントの設定
		this.popup.on('open', () => {
			document.getElementById('markerEditButton').addEventListener('click', this.markerEdit.bind(this));
			document.getElementById('markerMoveButton').addEventListener('click', this.markerMove.bind(this));
			document.getElementById('markerCopyButton').addEventListener('click', this.markerCopy.bind(this));
			document.getElementById('markerCopyButtonB').addEventListener('click', this.copyToClipboard.bind(this));
			document.getElementById('markerRemoveButton').addEventListener('click', this.markerRemove.bind(this));
		});
	}



	setDistance(distance) {
		this.distance = distance;
		this.updateMarkerPosition();
	}

    closePopup() {
		this.boss.currentMarker = null;
		if (this.popup) {
			this.popup.remove();
		}
    }

	addToMap() {
		if(this.marker) this.marker.addTo(this.map);
	}

    // ボタンのクリックイベントハンドラーを定義
    markerEdit() {
        this.boss.markerEdit(this);
    }

    markerMove() {
        this.boss.markerMove(this);
    }

    markerCopy() {
        this.boss.markerCopy(this);
    }

    markerRemove() {
        this.boss.markerRemove(this);
    }
	startMove() {
		if (this.marker && this.marker.getElement()) {
			this.closePopup();
			this.marker.getElement().classList.add('blinking-marker');
			this.marker.setDraggable(true); // ドラッグ可能にする
		}
	}

	stopMoving() {
		if (this.marker && this.marker.getElement()) {
			this.marker.getElement().classList.remove('blinking-marker');
			this.marker.setDraggable(false); // ドラッグを無効にする
		}
	}


	drawMarkerShape(color, size,textTop,textBottom) {
		var canvas = document.createElement('canvas');
		canvas.width = size * 2;
		canvas.height = size * 2;
		var ctx = canvas.getContext('2d');

		ctx.clearRect(0, 0, size, size);
		ctx.strokeStyle = '#fff';
		ctx.lineWidth = size / 8;

		const centerX = ctx.canvas.width / 2;
		const centerY = ctx.canvas.height / 2;
		const halfSize = size / 2;

		// Drawing the diamond shape
		ctx.beginPath();
		ctx.moveTo(centerX, centerY - halfSize); // Top
		ctx.lineTo(centerX + halfSize, centerY); // Right
		ctx.lineTo(centerX, centerY + halfSize); // Bottom
		ctx.lineTo(centerX - halfSize, centerY); // Left
		ctx.closePath();
		ctx.stroke();
		// Drawing the plus sign
		ctx.beginPath();
		ctx.moveTo(centerX, centerY - halfSize / 3);
		ctx.lineTo(centerX, centerY + halfSize / 3);
		ctx.moveTo(centerX - halfSize / 3, centerY);
		ctx.lineTo(centerX + halfSize / 3, centerY);
		ctx.stroke();

		ctx.strokeStyle = color;
		ctx.lineWidth = size / 16;

		// Drawing the diamond shape
		ctx.beginPath();
		ctx.moveTo(centerX, centerY - halfSize); // Top
		ctx.lineTo(centerX + halfSize, centerY); // Right
		ctx.lineTo(centerX, centerY + halfSize); // Bottom
		ctx.lineTo(centerX - halfSize, centerY); // Left
		ctx.closePath();
		ctx.stroke();

		// Drawing the plus sign
		ctx.beginPath();
		ctx.moveTo(centerX, centerY - halfSize / 4);
		ctx.lineTo(centerX, centerY + halfSize / 4);
		ctx.moveTo(centerX - halfSize / 4, centerY);
		ctx.lineTo(centerX + halfSize / 4, centerY);
		ctx.stroke();

		//----------------------
		let fontSize = size / 3; // Start with a reasonable size
		ctx.font = `bold ${fontSize}px Arial`;
		let textWidth = ctx.measureText(textTop).width;

		while (textWidth > size*2 && fontSize > 10) {
			fontSize--;
			ctx.font = `bold ${fontSize}px Arial`;
			textWidth = ctx.measureText(textTop).width;
		}
		ctx.fillStyle = color;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'bottom';

		ctx.lineWidth = 3;
		ctx.strokeStyle = '#ffffff';
		ctx.strokeText(textTop, centerX, centerY - halfSize - 5);

		// Draw the text
		ctx.fillStyle = color;
		ctx.fillText(textTop, centerX, centerY - halfSize - 5); 
		//----------------------
		fontSize = size / 3; // Start with a reasonable size
		ctx.font = `bold ${fontSize}px Arial`;
		textWidth = ctx.measureText(textBottom).width;

		while (textWidth > size*2 && fontSize > 10) {
			fontSize--;
			ctx.font = `bold ${fontSize}px Arial`;
			textWidth = ctx.measureText(textBottom).width;
		}
		ctx.fillStyle = color;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'top';
		ctx.lineWidth = 3;
		ctx.strokeStyle = '#ffffff';
		ctx.strokeText(textBottom, centerX, centerY + halfSize + 5);
		ctx.fillStyle = color;
		ctx.fillText(textBottom, centerX, centerY + halfSize + 5); // Adjust the position as needed
		return canvas.toDataURL('image/png');
	}

	makeIconImageWithNumber(iconNumber, textTop, textBottom,size, callback) {
		if(iconNumber < 0 || iconNumber > MARKER_ARRAY.length) return;

		var canvas = document.createElement('canvas');
		canvas.width = size;
		canvas.height = size;
		const offset = size * 0.2;
		const margin = size * 0.1;
		const color = Marker._markerColors[iconNumber % 6];
		var ctx = canvas.getContext('2d');
		const imgUrl = MARKER_ARRAY[iconNumber];

		const isEmoji = Marker.getFirstEmojiOrFullWidthSymbol(textTop);

		if(isEmoji.first) {
			const center = size / 2;
			const emojiSize = size * 0.5;
			ctx.fillStyle = color;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'center';
			ctx.font = `${emojiSize}px Arial`;
			ctx.lineWidth = size / 20;
			ctx.strokeStyle = '#ffffff';
			ctx.strokeText(isEmoji.first, center, emojiSize * 1.33);

			// Draw the text
			ctx.fillStyle = color;
			ctx.fillText(isEmoji.first, center, emojiSize * 1.33);

			//--------------
			const topResult = Marker.calcFontSize(ctx,size,margin,isEmoji.residue,size / 7);

			ctx.fillStyle = color;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'bottom';
			ctx.font = `bold ${topResult.fontSize}px Arial`;
			ctx.lineWidth = size / 20;
			ctx.strokeStyle = '#ffffff';
			ctx.strokeText(topResult.text, center, topResult.fontSize + size / 20);

			// Draw the text
			ctx.fillStyle = color;
			ctx.fillText(topResult.text, center, topResult.fontSize + size / 20);
			//----------------------
			const bottomResult = Marker.calcFontSize(ctx,size,margin,textBottom,size / 7);
			ctx.fillStyle = color;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'top';
			ctx.font = `bold ${bottomResult.fontSize}px Arial`;

			ctx.lineWidth = size / 20;
			ctx.strokeStyle = '#ffffff';
			ctx.strokeText(bottomResult.text, center, size - bottomResult.fontSize);
			ctx.fillStyle = color;
			ctx.fillText(bottomResult.text, center, size - bottomResult.fontSize); // Adjust the position as needed

			callback(canvas.toDataURL('image/png'));
		}
		else {
			var iconImage = new Image();
			iconImage.crossOrigin = "Anonymous";

			iconImage.onload = function () {

				const center = size / 2;
				ctx.drawImage(iconImage, offset, offset, size - offset*2, size - offset*2);			

				const topResult = Marker.calcFontSize(ctx,size,margin,textTop,size / 7);

				ctx.fillStyle = color;
				ctx.textAlign = 'center';
				ctx.textBaseline = 'bottom';
				ctx.font = `bold ${topResult.fontSize}px Arial`;
				ctx.lineWidth = size / 20;
				ctx.strokeStyle = '#ffffff';
				ctx.strokeText(topResult.text, center, topResult.fontSize + size / 20);

				// Draw the text
				ctx.fillStyle = color;
				ctx.fillText(topResult.text, center, topResult.fontSize + size / 20);
				//----------------------
				const bottomResult = Marker.calcFontSize(ctx,size,margin,textBottom,size / 7);
				ctx.fillStyle = color;
				ctx.textAlign = 'center';
				ctx.textBaseline = 'top';
				ctx.font = `bold ${bottomResult.fontSize}px Arial`;

				ctx.lineWidth = size / 20;
				ctx.strokeStyle = '#ffffff';
				ctx.strokeText(bottomResult.text, center, size - bottomResult.fontSize);
				ctx.fillStyle = color;
				ctx.fillText(bottomResult.text, center, size - bottomResult.fontSize); // Adjust the position as needed
				callback(canvas.toDataURL('image/png'));
			};
			iconImage.onerror = function (error) {
				console.error("Image failed to load", error);
			};
			iconImage.src = imgUrl;
		}

		return;
	}

	static MIN_HIGH_SURROGATE = 0xD800;
	static MAX_HIGH_SURROGATE = 0xDBFF;
	static MIN_LOW_SURROGATE = 0xDC00;
	static MAX_LOW_SURROGATE = 0xFE0F;

	static isEmoji(char) {
		const code = char.charCodeAt(0);
		return Marker.isHighSurrogate(code) || Marker.isLowSurrogate(code);
	}

	static isHighSurrogate(code) {
		return Marker.MIN_HIGH_SURROGATE <= code && code <= Marker.MAX_HIGH_SURROGATE;
	}

	static isLowSurrogate(code) {
		return Marker.MIN_LOW_SURROGATE <= code && code <= Marker.MAX_LOW_SURROGATE;
	}

	static isKigou(char) {
		const code = char.charCodeAt(0);
		return (
			code === 0x3004 || code === 0x3012 || code === 0x3020 || code === 0x3030 || code === 0x3036 || code === 0x303c || code === 0x303d ||
			(code >= 0x3200 && code <= 0x33ff) ||
			(code >= 0x2020 && code <= 0x2bff)
		);
	}
	
	static getFirstEmojiOrFullWidthSymbol(str) {
		if (!str || typeof str !== 'string') {
			return '';
		}
		let first = str[0];
		const second = str.length > 1 ? str[1] : '';

		if (Marker.isKigou(first)) {
			const residue = str.replace(first, "");
			return { first, residue };
		}

		if (Marker.isEmoji(first + second)) {
			first = first + second;
			const residue = str.replace(first, "");
			return { first, residue }
		}

		if (Marker.isEmoji(first)) {
			const residue = str.replace(first, "");
			return { first, residue };
		}

		first = null;
		const residue = str;
		return { first, residue };
	}
	static calcFontSize(ctx,size,margin,text,minfontSize) {
		let fontSize = size / 4; // Start with a reasonable size
		ctx.font = `bold ${fontSize}px Arial`;
		let textWidth = ctx.measureText(text).width;

		while (textWidth > size- margin && fontSize > minfontSize) {
			fontSize--;
			ctx.font = `bold ${fontSize}px Arial`;
			textWidth = ctx.measureText(text).width;
		}
		if(textWidth > size- margin) {
			ctx.font = `bold ${fontSize}px Arial`;
			while (textWidth > size- margin) {
				text = text.slice(0, -2) + "…";
				textWidth = ctx.measureText(text).width;
			}
		}
		return {fontSize,text};
	}

	static areFirstTwoCharsAscii(str) {
		return /^[ -~]{2}/.test(str);
	}
	remove() {
		if (this.marker) {
			this.marker.remove();
		}
	}
}

export default Marker;
