import React, { useState, useEffect } from 'react';
import { Translator, resources } from './Translator';
import { CommonUtils } from './CommonUtils';
import { Padding } from 'maplibre-gl';

const translator = new Translator(resources);

const CustomLayerSettingPopup = ({ layer, onSave, onClose }) => {
	const [name, setName] = useState(layer.name || '');
	const [cp, setCp] = useState(layer.cp || '');
	const [url, setUrl] = useState(layer.url || '');
	const [minz, setMinz] = useState(layer.minz || 1);
	const [mz, setMz] = useState(layer.mz || 18);
	const [op, setOp] = useState(layer.op || 100);
    const [bp, setBp] = useState(layer.bp || 0);
    const [cc, setCc] = useState(layer.cc || 0);
    const [proxy, setProxy] = useState(layer.proxy || false);

	const t = (key) => translator.t(key);

	const handleSave = () => {
		onSave({ ...layer, name, cp, url, minz: Number(minz), mz: Number(mz), op: Number(op),bp:Number(bp),cc:Number(cc),proxy });
		onClose();
	};

	return (
		<div className="popup" style={{ maxWidth: '480px',minWidth: '240px'}}>
		<div className="popup-inner">
			<h3>{t('Custom layer settings')}</h3>
			<label>{t('Name')}: <input type="text" value={name} onChange={(e) => setName(e.target.value)} /></label>
			<label>{t('Copylight')}: <input type="text" value={cp} onChange={(e) => setCp(e.target.value)} /></label>
			<label>{t('URL')}: <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} /></label>
			<div className="checkbox-container">
				<label>
					<input 
						type="checkbox" 
						checked={proxy} 
						onChange={(e) => setProxy(e.target.checked)} 
					/>
					<span>{t('Use proxy')}</span>
				</label>
			</div>
			<label>{t('Min Zoom')}: <input type="number" min="1" max="18" value={minz} onChange={(e) => setMinz(e.target.value)} /></label>
			<label>{t('Max Zoom')}: <input type="number" min="1" max="18" value={mz} onChange={(e) => setMz(e.target.value)} /></label>
			<label>
				{t('Opacity')}: <span>{op}%</span>
				<input 
					type="range" 
					min="0" 
					max="100" 
					value={op} 
					onChange={(e) => setOp(e.target.value)} 
				/>
			</label>
			<label>
				{t('ReferencePoint')}:
				<select value={bp} onChange={(e) => setBp(e.target.value)}>
					<option value="0">{t('NW')}</option>
					<option value="1">{t('SW')}</option>
				</select>
			</label>
			<label>
				{t('Color conversion')}:
				<select value={cc} onChange={(e) => setCc(e.target.value)}>
					<option value="0">{t('do not')}</option>
					<option value="1">{t('white to red')}</option>
				</select>
			</label>
			<button className="button_b" onClick={onClose}>{t('Cancel')}</button>
			<button className="button_y" onClick={handleSave}>{t('Save')}</button>
		</div>
		</div>
	);
};


const LayerSettingPopup = ({ overlays,overlayopacity, maps,customOverlays, customMaps, currentOverlay, currentMap, onClose, onApply,onUpdateCustomLayer,  currentExaggeration, onExaggerationChange}) => {
	const [selectedOverlay, setSelectedOverlay] = useState(currentOverlay);
	const [overlayOpacity, setOverlayOpacity] = useState(CommonUtils.overlays[selectedOverlay] ? CommonUtils.overlays[selectedOverlay].op : 40);
	const [selectedMap, setSelectedMap] = useState(currentMap);
	const [showCustomOverlaySettings, setShowCustomOverlaySettings] = useState(false);
	const [showCustomMapSettings, setShowCustomMapSettings] = useState(false);
	const [exaggeration, setExaggeration] = useState(currentExaggeration || 1);

	const t = (key) => translator.t(key);

	const handleApply = () => {
		onApply(selectedOverlay, overlayOpacity, selectedMap,exaggeration);
		onClose();
	};

	const isCustomOverlay = Object.keys(customOverlays).includes(selectedOverlay);
	const isCustomMap = Object.keys(customMaps).includes(selectedMap);

	const handleSelectedOverlay = (value) => {
		setSelectedOverlay(value);
		if(CommonUtils.overlays[value]) setOverlayOpacity(CommonUtils.overlays[value].op || 40);
	};

	const handleExaggerationChange = (event) => {
		const value = parseFloat(event.target.value);
		setExaggeration(value);
		onExaggerationChange(value);
	};

	return (
		<div className="popup">
		<div className="popup-inner">
			<h2>{t('Layer Settings')}</h2>
			<label>
			{t('Overlay')}
			<select
				value={selectedOverlay}
				onChange={(e) => handleSelectedOverlay(e.target.value)}
			>
				{Object.entries(overlays).map(([key, value]) => (
				<option key={key} value={key}>
					{value.name}
				</option>
				))}
				{Object.entries(customOverlays).map(([key, value]) => (
				<option key={key} value={key}>
					{value.name}
				</option>
				))}

			</select>
			</label>
			{isCustomOverlay  && (
				<button className="button_b" onClick={() => setShowCustomOverlaySettings(true)}>{t('Custom layer settings')}</button>
			)}
			{!isCustomOverlay  && (
				<label>
					{t('Opacity')}: <span>{overlayOpacity}%</span>
					<input 
						type="range" 
						min="0" 
						max="100" 
						value={overlayOpacity} 
						onChange={(e) => setOverlayOpacity(e.target.value)} 
					/>
				</label>

			)}
			<label>
			{t('Base Map')}
			<select
				value={selectedMap}
				onChange={(e) => setSelectedMap(e.target.value)}
			>
				{Object.entries(maps).map(([key, value]) => (
				<option key={key} value={key}>
					{value.name}
				</option>
				))}
				{Object.entries(customMaps).map(([key, value]) => (
				<option key={key} value={key}>
					{value.name}
				</option>
				))}				
			</select>
			</label>
			<div>
				<label>{t('Terrain Exaggeration')}: {exaggeration.toFixed(1)}x</label>
				<input
					type="range"
					min="1"
					max="10"
					step="0.1"
					value={exaggeration}
					onChange={handleExaggerationChange}
				/>
			</div>
			{isCustomMap && (
				<button className="button_b" onClick={() => setShowCustomMapSettings(true)}>{t('Custom layer settings')}</button>
			)}
			<div>
				<button className="button_b" onClick={onClose}>{t('Cancel')}</button>
				<button className="button_y" onClick={handleApply}>{t('Apply')}</button>
			</div>
		</div>
		{showCustomOverlaySettings && (
			<CustomLayerSettingPopup
			layer={customOverlays[selectedOverlay]}
			onSave={(updatedLayer) => {
				onUpdateCustomLayer('overlay', selectedOverlay, updatedLayer);
				setShowCustomOverlaySettings(false);
			}}
			onClose={() => setShowCustomOverlaySettings(false)}
			/>
		)}
		{showCustomMapSettings && (
			<CustomLayerSettingPopup
			layer={customMaps[selectedMap]}
			onSave={(updatedLayer) => {
				onUpdateCustomLayer('map', selectedMap, updatedLayer);
				setShowCustomMapSettings(false);
			}}
			onClose={() => setShowCustomMapSettings(false)}
			/>
		)}
		</div>
	);
};

export default LayerSettingPopup;