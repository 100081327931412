import React, { useState } from 'react';
import './css/popup.css';
import { Translator, resources } from './Translator';

const translator = new Translator(resources);

const ExistingProjectPopup = ({ onClose,onItemClick }) => {
    const t = (key) => translator.t(key);

    // 適当なデータを定義
    const [ProjectList, setProjectList] = useState([
        { id: 1, title: 'Collabo 1' },
        { id: 2, title: 'Collabo 2' },
        { id: 3, title: 'Collabo 3' },
        { id: 3, title: 'Collabo 3' },
        { id: 3, title: 'Collabo 3' },
        { id: 3, title: 'Collabo 3' },
        { id: 3, title: 'Collabo 3' },
        { id: 3, title: 'Collabo 3' },
    ]);

    const handleItemClick = (id) => {
        onItemClick(id);
    };
    return (
        <div className="popup">
            <span className="close_button" onClick={onClose}>&times;</span>
            <div className="popup-inner">
                <h2>{t('exsitingProjectList')}</h2>
                <div className="scrollable-list">
                    <ul>
                        {ProjectList.map(project => (
                            <li key={project.id} onClick={() => handleItemClick(project.id)}>
                                {project.title}
                            </li>
                        ))}
                    </ul>
                </div>
                <button onClick={onClose}>{t('Close')}</button>
            </div>
        </div>
    );
};

export default ExistingProjectPopup;
