import React, { useState, useEffect } from 'react';
import { Translator, resources } from './Translator';

const translator = new Translator(resources);

const SearchComponent = ({ fm, onSearch, initialKeyword }) => {
	const t = (key) => translator.t(key);
	const [isExpanded, setIsExpanded] = useState(false);
	const [searchTerm, setSearchTerm] = useState(initialKeyword || '');
	const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 520);

	useEffect(() => {
		const handleResize = () => {
			setIsWideScreen(window.innerWidth > 520);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		setSearchTerm(initialKeyword || '');
	}, [initialKeyword]);

	const handleSubmit = (e) => {
		e.preventDefault();
		onSearch(searchTerm);
	};

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div className={`search-container ${isExpanded ? 'expanded' : ''}`}>
			{(isWideScreen || isExpanded) ? (
				<form onSubmit={handleSubmit} className="search-form">
					{!isWideScreen && (
						<button type="button" onClick={toggleExpand} className="close-button">
							&#x2715;
						</button>
					)}
					<input
						type="text"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						placeholder={t('Search')}
						className="search-input"
					/>
					<button type="submit" className="search-button">
						&#x1F50D;
					</button>
				</form>
			) : (
				<button onClick={toggleExpand} className="search-icon-button">
					&#x1F50D;
				</button>
			)}
		</div>
	);
};



//リアルタイム検索バージョン　クエリが多すぎるので一旦お蔵
/*const SearchComponent = ({ fm, onSearch, searchInput, setSearchInput, limit }) => {
	const t = (key) => translator.t(key);
	const [isExpanded, setIsExpanded] = useState(false);
	const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 520);

	useEffect(() => {
		const handleResize = () => {
		setIsWideScreen(window.innerWidth > 520);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (searchInput) {
		handleSearch();
		}
	}, [searchInput]);

	const handleSearch = async (e) => {
		if (e) e.preventDefault();
		console.log('Searching for:', searchInput);

		try {
		const snapshot = await fm.searchProject(searchInput, limit);
		const results = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
		onSearch(searchInput, results);
		} catch (error) {
		console.error('Error during search:', error);
		}
	};

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div className={`search-container ${isExpanded ? 'expanded' : ''}`}>
		{(isWideScreen || isExpanded) ? (
			<form onSubmit={handleSearch} className="search-form">
			{!isWideScreen && (
				<button type="button" onClick={toggleExpand} className="close-button">
				&#x2715;
				</button>
			)}
			<input
				type="text"
				value={searchInput}
				onChange={(e) => setSearchInput(e.target.value)}
				placeholder={t('Search')}
				className="search-input"
			/>
			<button type="submit" className="search-button">
				&#x1F50D;
			</button>
			</form>
		) : (
			<button onClick={toggleExpand} className="search-icon-button">
			&#x1F50D;
			</button>
		)}
		</div>
	);
};*/

export default SearchComponent;