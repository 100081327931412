import React from 'react';

const ShapeOperationPanel = ({ onConfirm, onUndo, position }) => {
	// 画面上の位置を計算
	const style = {
		left: position ? `${position.x}px` : '50%',
		top: position ? `${position.y}px` : '50%',
		transform: 'translate(+100%, -150%)',
		position: 'absolute',
		zIndex: 1000
	};
	const buttonStyle = {
		backgroundColor: '#f3f4f6', // bg-gray-100相当
		padding: '0.25rem',          // p-2相当
		borderRadius: '0.5rem',     // rounded-lg相当
	};

	return (
		<div 
		className="bg-white rounded-lg shadow-lg p-2 flex gap-2" 
		style={style}
		>
			<button
				onClick={onConfirm}
				style={buttonStyle}
				title="Confirm"
			>
				<img 
				src="/img/check_b.png" 
				alt="Confirm" 
				width="24" 
				height="24"
				/>
			</button>
			<button
				onClick={onUndo}
				style={buttonStyle}
				title="Undo last point"
			>
				<img 
				src="/img/icon_undo_b.png" 
				alt="Undo" 
				width="24" 
				height="24"
				/>
			</button>
		</div>
	);
};

export default ShapeOperationPanel;