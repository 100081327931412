import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Translator, resources } from './Translator';
import './css/PhotoViewer.css';

const translator = new Translator(resources);

const PhotoViewer = ({ 
		photo, 
		photoMarkers,
		myUid, 
		onClose, 
		onPrev, 
		onNext, 
		currentIndex, 
		totalPhotos, 
		isFullscreen,
		toggleFullscreen,
		onPhotoSelect,
		onDelete,
		onMoveStart,
		onUpdateMemo
	}) => {
	const t = (key) => translator.t(key);
	const [isInfoVisible, setIsInfoVisible] = useState(true);
	const [rotation, setRotation] = useState(0);
	const [photoDataUrl, setPhotoDataUrl] = useState(null);
	const containerRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [note, setNote] = useState(photo.note || '');
    const [timeoutId, setTimeoutId] = useState(null);

	useEffect(() => {
		setNote(photo.note || '');
		setIsExpanded(false); // 新しい写真に切り替わったときは入力欄を閉じる
	}, [photo]);

	const preloadImages = useCallback((index) => {
		const preloadIndexes = [
			(index - 1 + totalPhotos) % totalPhotos,
			index,
			(index + 1) % totalPhotos
		];

		preloadIndexes.forEach(i => {
			if (i >= 0 && photoMarkers[i] && photoMarkers[i].photoUrl) {
				const img = new Image();
				img.src = photoMarkers[i].photoUrl;
			}
		});
	}, [photoMarkers, totalPhotos]);


	useEffect(() => {
		const fetchImage = async () => {
			try {
				const photoResponse = await fetch(photo.url);
				const photoBlob = await photoResponse.blob();
				const dataUrl = URL.createObjectURL(photoBlob);
				setPhotoDataUrl(dataUrl);
			} catch (error) {
				console.error('Error fetching image:', error);
			}
		};

		fetchImage();
		setRotation(0);
		preloadImages(currentIndex);

		return () => {
			if (photoDataUrl) {
				URL.revokeObjectURL(photoDataUrl);
			}
		};
	}, [photo.url, currentIndex, preloadImages]);

	const handleKeyDown = useCallback((event) => {
		if (isExpanded) {
			// メモ入力中はEscapeキーのみ処理
			if (event.key === 'Escape') {
				setIsExpanded(false);
			}
			return;
		}

		switch (event.key) {
			case 'Escape':
				if (isFullscreen) {
					toggleFullscreen();
				} else {
					onClose();
				}
				break;
			case 'ArrowLeft':
				onPrev();
				break;
			case 'ArrowRight':
				onNext();
				break;
			case 'f':
				toggleFullscreen();
				break;
			default:
				break;
		}
	}, [onClose, onPrev, onNext, toggleFullscreen, isFullscreen, isExpanded]);


	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	const rotateImage = () => {
		setRotation((prevRotation) => (prevRotation + 90) % 360);
	};

	const resetAll = (resetTransform) => {
		resetTransform();
		setRotation(0);
	};

	useEffect(() => {
		if (isFullscreen) {
			containerRef.current.requestFullscreen();
		} else if (document.fullscreenElement) {
			document.exitFullscreen();
		}
	}, [isFullscreen]);

	if (!photo || !photoDataUrl) return null;

	const handleMemoChange = (e) => {
		setNote(e.target.value);
		if (timeoutId) clearTimeout(timeoutId);
		const newTimeoutId = setTimeout(() => {
			onUpdateMemo(photo.docId, e.target.value);
		}, 3000);
		setTimeoutId(newTimeoutId);
	};

	const handleMemoKeyDown = (e) => {
		if (e.key === 'Enter') {
			onUpdateMemo(photo.docId, note);
			setIsExpanded(false);
		}
	};

	return (
		<div 
			ref={containerRef}
			className={`photo-viewer-overlay ${isFullscreen ? 'fullscreen' : ''}`}
		>
			<TransformWrapper
				initialScale={1}
				centerOnInit={true}
				limitToBounds={true}
				minScale={0.5}
				maxScale={3}
				doubleClick={{ disabled: false }}
				pinch={{ disabled: false }}
				pan={{ disabled: false }}
				centerZoomedOut={true}
				centerZoomedIn={true}
			>
				{({ zoomIn, zoomOut, resetTransform }) => (
					<React.Fragment>
						<TransformComponent 
							wrapperClass="transform-wrapper"
							contentClass="transform-content"
						>
							<img 
							id="photoImage"
							src={photoDataUrl} 
							alt={photo.name} 
							style={{ 
								transform: `rotate(${rotation}deg)`,
								touchAction: 'pinch-zoom' 
							}}
							/>
						</TransformComponent>
						<div className="controls">
							<button onClick={onPrev}>{t('Previous')}</button>
							<button onClick={rotateImage}>{t('Rotate')}</button>
							<button onClick={() => zoomIn()}>+</button>
							<button onClick={() => zoomOut()}>-</button>
							<button onClick={() => resetAll(resetTransform)}>{t('Reset')}</button>
							<button onClick={toggleFullscreen}>{isFullscreen ? t('ExitFullscreen') : t('Full_screen')}</button>
							<button onClick={onNext}>{t('Next')}</button>
						</div>
					</React.Fragment>
				)}
			</TransformWrapper>
			
			<div className={`photo-info ${isInfoVisible ? 'visible' : ''}`}>
				<div className="info-content">
					<p>{t('ShootingDateTime')}: {photo.dateTimeOriginal}</p>
					<p>{t('camera')}: {photo.cameraModel}</p>
					<p>{photo.fileName}</p>
				</div>
			</div>
				<button className="close-button-photo" onClick={onClose}>{t('Close')}</button>
				
			{photo.owneruid === myUid && ( <>
				<button className="move-button" onClick={() => onMoveStart(photo)}>{t('move')}</button>
				<button className="delete-button" onClick={() => onDelete(photo)}>{t('delete')}</button>
			</>)}

			<div className="memo-input-container">
				{photo.owneruid === myUid ? (
					isExpanded ? (
						<input
							type="text"
							value={note}
                            onChange={handleMemoChange}
							onKeyDown={handleMemoKeyDown}
							onBlur={() => setIsExpanded(false)}
							autoFocus
						/>
					) : (
						<div onClick={() => setIsExpanded(true)}>
							{note || t('AddMemo')}
						</div>
					)
				) : (
					note && <div>{note}</div>
				)}
			</div>

			<div className="thumbnail-gallery-overlay">
				<div className="thumbnail-gallery">
					{photoMarkers.map((marker, index) => (
						<img
							key={marker.docId}
							src={marker.iconDataUrl}
							alt={`Thumbnail ${index + 1}`}
							className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
							onClick={() => onPhotoSelect(index)}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default PhotoViewer;
