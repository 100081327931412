import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonUtils } from './CommonUtils';
import { Translator, resources } from './Translator';

const translator = new Translator(resources);

const ProjectCardGrid = ({ fm, items,isReadThumbnail,onTagClick,onFavoriteToggle,isNoMapShow = true  }) => {
	const navigate = useNavigate();
	const t = (key) => translator.t(key);
	const [projectsWithThumbnails, setProjectsWithThumbnails] = useState([]);

	const handleFavoriteClick = (e, project) => {
		e.stopPropagation(); // Prevent the project card click event from firing
		onFavoriteToggle(project.id, !project.isfavorite);
	};

	useEffect(() => {
		const loadThumbnails = async () => {
			const updatedProjects = await Promise.all(items.map(async (project) => {
				let thumbnailUrl = project.thumbnailUrl;
				if (isReadThumbnail && !thumbnailUrl) {
					if (project.id && project.id.includes('_')) {
						const parts = project.id.split('_');
						if (parts.length >= 2) {
							project.id = parts[1];
						}
					}
					const thumbnailPath = `files/${project.owneruid}/${project.id}/${project.owneruid}/open/screenshot.jpg`;
					thumbnailUrl = await fm.getFirebaseStorageUrl(thumbnailPath);
					if(!thumbnailUrl) thumbnailUrl = "img/screenshot.png";

					/*const myCollectionPath = `userdata/${fm.auth.currentUser.uid}/projects/`;//なんか負荷が高そうなのでやめてみる？
					let data = null;
					data = {
						thumbnailUrl:thumbnailUrl,
					};
					try {
						fm.updateDocument(myCollectionPath, project.id,data);
					} catch (error) {
						console.error('Failed to updateDocument:', error);
					}*/
				}
				return { ...project, thumbnailUrl };
			}));
			setProjectsWithThumbnails(updatedProjects);
		};

		loadThumbnails();
	}, [items, fm]);

	const handleItemClick = (project) => {
		const lat = project.lat ? project.lat : 36.238642;
		const lng = project.lng ? project.lng : 137.969392;
		const zoom = project.zoom ? project.zoom : 13;

		if (project.id.includes('_')) {//検索結果から飛ぶ場合、idはuid_uuidの形なので前半を消す
			const parts = project.id.split('_');
			if(parts.length === 2 && parts[1].length > 0) project.id = parts[1];
		}
			
		navigate(`/map/${project.id}_${project.owneruid}#${zoom}/${lat}/${lng}`);
	};

	const truncateMemo = (memo) => {
		if (memo.length > 64) {
			return memo.substring(0, 64) + '...';
		}
		return memo;
	};

	const handleTagClick = (e, tag) => {
		e.stopPropagation(); // Prevent the project card click event from firing
		onTagClick(tag);
	};

	if (isNoMapShow && items.length === 0) {
		return (
		<div className="project-grid">
			<p className="no-data-message">{t('No Map')}</p>
		</div>
		);
	}
	return (
		<div className="project-grid">
			{projectsWithThumbnails.map(project => {
				let updatetime = project.updatetime ? project.updatetime.toDate() : new Date();
				const timeString = CommonUtils.formatDateWithIsSec(updatetime);
				const tags = project.tags || [];

				return (
					<div key={project.id} className="project-card" onClick={() => handleItemClick(project)}>
						<div className="project-thumbnail">
							{project.thumbnailUrl && <img src={project.thumbnailUrl} alt={project.title} />}
						</div>
						<span className="project-title">{project.title}</span> <span className="project-owner">{project.ownername}</span>
						{project.memo && <p className="project-memo">{truncateMemo(project.memo)}</p>}
						<p className="project-time">{timeString}</p>
						<p className="project-tags">
							{tags.map((tag, index) => (
								<span 
								key={index} 
								className="project-tag" 
								onClick={(e) => handleTagClick(e, tag)}
								>
								#{tag}
								</span>
							))}
						</p>
						{onFavoriteToggle &&
							<img 
								className="favorite-button" 
								src={project.isfavorite ? "./img/icon_favorite_true.png" : "./img/icon_favorite_b.png"}
								alt={project.isfavorite ? "Favorite" : "Not favorite"}
								onClick={(e) => handleFavoriteClick(e, project)}
							/>
						}
					</div>
				);
			})}
		</div>
	);
};

export default ProjectCardGrid;