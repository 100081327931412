import React, { useState } from 'react';
import './css/popup.css';
import { MARKER_ARRAY } from './constants';

const MarkerSelectPopup = ({ onSubmit, onClose }) => {
	const [iconNumber, setIconNumber] = useState('');

	const handleIconClick = (index) => {
		setIconNumber(index);
		onClose();
		onSubmit({ iconNumber: index,iconSrc: MARKER_ARRAY[index] });
	};

	return (
		<div id="markerSelectPopup" className="popup" style={{opacity:0.9 }}>
			<span className="close_button" onClick={onClose}>&times;</span>
			<div className="marker_grid_container" id="markerGrid">
				{MARKER_ARRAY.map((url, index) => (
					<div className="marker_grid_item" key={index} onClick={() => handleIconClick(index)}>
						<img src={url} alt={`Marker ${index}`} />
					</div>
				))}				
			</div>
			<p></p>
			<style jsx="true">{`
				.marker_grid_container {
					display: grid;
					grid-template-columns: repeat(6, 35px);
					grid-template-rows: repeat(8, 35px);
					gap: 5px;
				}

				.marker_grid_item img {
					width: 35px;
					height: 35px;
					cursor: pointer;
					background-color: #fff;
					border-radius: 5px;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					-webkit-user-drag: none;
					-khtml-user-drag: none;
					-moz-user-drag: none;
					-o-user-drag: none;
				}

			`}</style>
		</div>
	);
};

export default MarkerSelectPopup;