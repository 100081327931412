import React, { useState,useEffect } from 'react';
import './css/popup.css';
import MarkerSelectPopup from './MarkerSelectPopup';
import { Translator, resources } from './Translator';
import { CommonUtils} from './CommonUtils';
import { MARKER_ARRAY } from './constants';
import elevationService from './ElevationService';

const translator = new Translator(resources);


const AddMarkerPopup = ({ onSubmit, onClose, lat, lng,defaultIconNumber}) => {
	const t = (key) => translator.t(key);
	const [name, setName] = useState('');
	const [elevation, setElevation] = useState('');
	const [note, setNote] = useState('');
	const [showMarkerSelect, setShowMarkerSelect] = useState(false);
	const [iconSrc, setIconSrc] = useState(MARKER_ARRAY[defaultIconNumber]);
	const [iconNumber, setIconNumber] = useState(defaultIconNumber);

	useEffect(() => {
		const fetchElevation = async () => {
			const elevationValue = await elevationService.getElevation(lat, lng);
			if (elevationValue !== null) {
				setElevation(elevationValue);
			}
		};

		fetchElevation();
	}, [lat, lng]);

	const handleSubmit = () => {
		onSubmit({ name, elevation, note,lat, lng, iconSrc, iconNumber });
		onClose();
	};

	const handleIconClick = () => {
		setShowMarkerSelect(true);
	};

	const handleIconSelect = ({ iconNumber, iconSrc }) => {
		setIconSrc(iconSrc);
		setIconNumber(iconNumber);
		setShowMarkerSelect(false);
	};

	return (
		<div className="popup_opaque" style={{ opacity: 0.9,minWidth: '250px',maxWidth: '500px' }}>
			<span className="close_button" onClick={onClose}>&times;</span>
			<div className="popup-inner">
				<h2>{t('Add a marker')}</h2>
				<label>
					{t('Marker name')}:
					<input type="text" value={name} onChange={(e) => setName(e.target.value)} />
				</label>
				<label>
					{t('Elevation')}:
					<input type="text" value={elevation} onChange={(e) => setElevation(e.target.value)} />
				</label>
				<label id="marker_memo">
					{t('Note')}:
					<textarea value={note} onChange={(e) => setNote(e.target.value)} />
				</label>
				<div className="rowBox" >
				<label id="iconLabel" className="popup-inner-label-nowrap">{t('Icon')}</label>
				<img className="selectedIcon" src={iconSrc} alt="Selected Icon" onClick={handleIconClick}></img>
				</div>
				<button className='button_b' onClick={onClose}>{t('Cancel')}</button>
				<button className='button_y' onClick={handleSubmit}>{t('Add')}</button>
			</div>
			{showMarkerSelect && (
				<MarkerSelectPopup onSubmit={handleIconSelect} onClose={() => setShowMarkerSelect(false)} />
			)}
			<style jsx="true">{`
				.popup,.popup_opaque {
					top: calc(50% + 20px) !important;
				}
			`}</style>
		</div>
	);
};

export default AddMarkerPopup;
