// src/constants.js

export const MARKER_ARRAY = [
    "/img/marker_p.png", "/img/marker_b.png", "/img/marker_g.png", "/img/marker_y.png",
    "/img/marker_r.png", "/img/marker_black.png", "/img/marker_pin_p.png", "/img/marker_pin_b.png",
    "/img/marker_pin_g.png", "/img/marker_pin_y.png", "/img/marker_pin_r.png", "/img/marker_pin_black.png",
    "/img/marker_kigou_house.png", "/img/marker_kigou_water.png", "/img/marker_kigou_mountain.png", "/img/marker_kigou_warning.png",
    "/img/marker_kigou_stop.png", "/img/marker_kigou_fish.png", "/img/marker_kigou_car.png", "/img/marker_kigou_airplane.png",
    "/img/marker_kigou_train.png", "/img/marker_kigou_animal.png", "/img/marker_kigou_hot.png", "/img/marker_kigou_bicycle.png",
    "/img/marker_char_p.png", "/img/marker_char_s.png", "/img/marker_char_a.png", "/img/marker_char_t.png",
    "/img/marker_char_g.png", "/img/marker_char_c.png", "/img/marker_num_1.png", "/img/marker_num_2.png",
    "/img/marker_num_3.png", "/img/marker_num_4.png", "/img/marker_num_5.png", "/img/marker_num_6.png",
    "/img/marker_kigou_kinoko.png", "/img/marker_kigou_bird.png", "/img/marker_kigou_sansai.png", "/img/marker_kigou_tento.png",
    "/img/marker_kigou_flower.png", "/img/marker_kigou_food.png", "/img/marker_toilet.png", "/img/marker_waterfall.png",
    "/img/marker_post.png", "/img/marker_paddle.png", "/img/marker_bench.png", "/img/marker_bus_stop.png"
];