import html2canvas from 'html2canvas';

export class MapLibrePrinter {
	constructor(map) {
		this.map = map;
		this.paperSizes = {
		'A4': { width: 210, height: 297 },
		'B4': { width: 257, height: 364 },
		'A3': { width: 297, height: 420 },
		'B3': { width: 364, height: 515 }
		};
	}

	async printMap(paperSize, orientation, scale) {
		try {
		const imageData = await this.captureMapScreenshot();
		this.print(imageData, paperSize, orientation);
		} catch (error) {
		console.error('Error printing map:', error);
		}
	}

	captureMapScreenshot() {
		return new Promise((resolve, reject) => {
		const originalUIState = this.toggleMapUI(false);

		html2canvas(this.map.getContainer(), {
			useCORS: true,
			allowTaint: true,
			foreignObjectRendering: true
		}).then(canvas => {
			this.toggleMapUI(originalUIState);
			const imageData = canvas.toDataURL('image/png');
			resolve(imageData);
		}).catch(error => {
			this.toggleMapUI(originalUIState);
			reject(new Error("Failed to capture map: " + error.message));
		});
		});
	}

	toggleMapUI(show) {
		const elements = document.querySelectorAll('.mapboxgl-ctrl, .maplibregl-ctrl, .toolButton');
		const originalState = elements[0] ? elements[0].style.display !== 'none' : true;
		elements.forEach(el => {
		el.style.display = show ? '' : 'none';
		});
		return originalState;
	}

	print(imageData, paperSize, orientation) {
		const { width, height } = this.paperSizes[paperSize];
		const printWindow = window.open('', '_blank');
		printWindow.document.write(`
		<html>
			<head>
			<title>Map Print</title>
			<style>
				@page {
				size: ${paperSize} ${orientation};
				margin: 0;
				}
				body {
				margin: 0;
				}
				img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				}
			</style>
			</head>
			<body>
			<img src="${imageData}" />
			<script>
				window.onload = function() {
				setTimeout(function() {
					window.print();
					window.close();
				}, 500);
				}
			</script>
			</body>
		</html>
		`);
		printWindow.document.close();
	}
}