import React, { useState, useEffect } from 'react';
import './css/LayerListPanel.css';
import { Translator, resources } from './Translator';

const LayerListPanel = ({ map, isOpen, onClose }) => {
	const [layers, setLayers] = useState([]);
	const translator = new Translator(resources);
	const t = (key) => translator.t(key);

	useEffect(() => {
		if (map && isOpen) {
			updateLayerList();
		}
	}, [map, isOpen]);

	const updateLayerList = () => {
		if (map) {
		const layerList = map.getStyle().layers.map(layer => ({
			id: layer.id,
			type: layer.type,
			visible: map.getLayoutProperty(layer.id, 'visibility') !== 'none'
		}));
		setLayers(layerList);
		}
	};

	const toggleLayerVisibility = (layerId) => {
		const visibility = map.getLayoutProperty(layerId, 'visibility');
		map.setLayoutProperty(
		layerId,
		'visibility',
		visibility === 'visible' ? 'none' : 'visible'
		);
		updateLayerList();
	};

	return (
		<div className={`layer-list-panel ${isOpen ? 'open' : ''}`}>
		<h2>{t('LayerSetting')}</h2>
		<button className="buttonN" onClick={onClose}>{t('Close')}</button>
		<ul>
			{layers.map(layer => (
			<li key={layer.id}>
				<input
				type="checkbox"
				checked={layer.visible}
				onChange={() => toggleLayerVisibility(layer.id)}
				/>
				{layer.id} ({layer.type})
			</li>
			))}
		</ul>
		</div>
	);
};

export default LayerListPanel;