// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './Main'; // 新しく作成する Main コンポーネントをインポート

const App = () => {
	return (
		<Router>
			<Main />
		</Router>
	);
};

export default App;
