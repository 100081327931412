import React, { useEffect, useRef } from 'react';
import maplibregl from 'maplibre-gl';

let isAlreadyAddPlusImage = false;

const DistanceLayer = ({ map,alwaysOnTop = true,update3dDistance}) => {
	const layerId = useRef('distance-markers-layer');
	const sourceId = useRef('distance-markers-source');

	useEffect(() => {
		if (!map) {
			console.error('Map object is not available');
			return;
		}

		// Canvasを使用してプラス記号を描画
		const createPlusIcon = () => {
//			console.log('Creating plus icon');
			const canvas = document.createElement('canvas');
			canvas.width = 15;
			canvas.height = 15;
			const ctx = canvas.getContext('2d');
			if (!ctx) {
				console.error('Failed to get 2D context');
				return null;
			}

			ctx.strokeStyle = 'red';
			ctx.lineWidth = 0.5;
			ctx.beginPath();
			ctx.moveTo(7.5, 0);
			ctx.lineTo(7.5, 15);
			ctx.moveTo(0, 7.5);
			ctx.lineTo(15, 7.5);
			ctx.stroke();

			const dataUrl = canvas.toDataURL();
//			console.log('Plus icon created:', dataUrl.substring(0, 50) + '...'); // データURLの一部だけを表示
			return dataUrl;
		};

		const iconUrl = createPlusIcon();
		if (!iconUrl) {
			console.error('Failed to create plus icon');
			return;
		}

		// 画像の読み込みを Promise でラップ
		const loadImage = () => {
			return new Promise((resolve, reject) => {
				console.log('Loading image');
				const img = new Image();
				img.onload = () => {
					console.log('Image loaded successfully');
					resolve(img);
				};
				img.onerror = (error) => {
					console.error('Error loading image:', error);
					reject(error);
				};
				img.src = iconUrl;
			});
		};

		const calculateDistanceForZoom = (zoom) => {
			// 基準となるズームレベルと距離
			const maxZoom = 17;
			const minDistance = 50; // 10,000 km at zoom level 1
			zoom =  Math.floor(zoom);

			if(zoom > maxZoom) return minDistance;
			const distance = minDistance  * Math.pow(2, maxZoom - zoom);
			return distance ;
		};
		const moveLayerToTop = () => {
			if (map.getLayer(layerId.current)) {
				map.moveLayer(layerId.current);
			}
		};
		// 非同期関数でレイヤーのセットアップを行う
		const setupLayer = async () => {
			try {
				if(!map.hasImage('plus-marker') && !isAlreadyAddPlusImage) {
					isAlreadyAddPlusImage = true;
					const image = await loadImage();
					map.addImage('plus-marker', image);
				}

				// ソースの追加
				if(!map.getSource(sourceId.current)) {
					map.addSource(sourceId.current, {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							features: []
						}
					});
				}

				// レイヤーの追加
				if(!map.getLayer(layerId.current)) {
					map.addLayer({
						id: layerId.current,
						type: 'symbol',
						source: sourceId.current,
						layout: {
							'icon-image': 'plus-marker',
							'icon-size': 1,
							'icon-allow-overlap': true
						}
					});
				}

				if (alwaysOnTop) {
					moveLayerToTop();
				}

				// ズーム変更時のイベントリスナー
				const updateMarkers = () => {
//					console.log('Updating markers');
					const zoom = map.getZoom();
					const bounds = map.getBounds();
					const distance = calculateDistanceForZoom(zoom);
					let south = bounds.getSouth();
					let north = bounds.getNorth()
					let east = bounds.getEast();
					let west = bounds.getWest()
					let centerLat = map.getCenter().lat;
					let centerLng = map.getCenter().lng;

					const maxZoom = 17;
					const minRange = 0.005;
					let range = 0.005;

					if( Math.floor(zoom) > maxZoom) range = minRange;
					range = minRange  * Math.pow(2, maxZoom -  Math.floor(zoom));

					if((north - centerLat) > range) north = centerLat + range;
					if((centerLat - south) > range) south = centerLat - range;
					if((east - centerLng) > range) east = centerLng + range;
					if((centerLng - west) > range) west = centerLng - range;
					//console.log(zoom + "/" + distance);
					update3dDistance(distance);
					const features = [];
					for (let lat = south; lat <= north; lat += distance / 111000) {
						for (let lon = west; lon <= east; lon += distance / (111000 * Math.cos(lat * Math.PI / 180))) {
							features.push({
								type: 'Feature',
								geometry: {
									type: 'Point',
									coordinates: [lon, lat]
								},
								properties: {}
							});
						}
					}

					if(map && map.getSource(sourceId.current)) {
						map.getSource(sourceId.current).setData({
							type: 'FeatureCollection',
							features: features
						});
//						console.log('Markers updated');
					}
				};

				map.on('zoom', updateMarkers);
				map.on('moveend', updateMarkers);

				const onStyleData = () => {
					if (alwaysOnTop) {
						moveLayerToTop();
					}
				};
				map.on('styledata', onStyleData);
				// 初回実行
				updateMarkers();

//				console.log('Layer setup completed');

				// クリーンアップ関数を返す
				return () => {
					console.log('Cleaning up');
					map.off('zoom', updateMarkers);
					map.off('moveend', updateMarkers);
					if (map.getLayer(layerId.current)) {
						map.removeLayer(layerId.current);
						console.log('Layer removed');
					}
					if (map.getSource(sourceId.current)) {
						map.removeSource(sourceId.current);
						console.log('Source removed');
					}
					if (map.hasImage('plus-marker')) {
						map.removeImage('plus-marker');
						console.log('Image removed');
					}
				};
			} catch (error) {
				console.error('Error setting up distance layer:', error);
			}
		};

		// setupLayerを実行し、返されたクリーンアップ関数を保存
		let cleanup;
		setupLayer().then(cleanupFunc => {
			if (cleanupFunc) {
				cleanup = cleanupFunc;
				isAlreadyAddPlusImage = false;
				console.log('Cleanup function saved');
			}
		}).catch(error => {
			console.error('Failed to setup layer:', error);
		});

		// コンポーネントのアンマウント時にクリーンアップを実行
		return () => {
			if (cleanup) {
				cleanup();
				console.log('Cleanup executed');
			}
		};
	}, [map]);

	return null;
};

export default DistanceLayer;