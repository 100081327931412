import React, { useState, useEffect } from 'react';
import { Translator, resources } from './Translator';
import './css/popup.css';

const translator = new Translator(resources);

const UserMenu = ({fm, onClose, onUserSettings, onAccountPermanent }) => {
	const t = (key) => translator.t(key);
	const [isAnonymous, setIsAnonymous] = useState(false);

	useEffect(() => {
		setIsAnonymous(fm.auth.currentUser?.isAnonymous || false);
	}, []);


	return (
		<div className="user-menu">
		<div className="user-menu-item" onClick={onUserSettings}>
			{t('User Settings')}
		</div>
		{isAnonymous && (
			<div className="user-menu-item" onClick={onAccountPermanent}>
				{t('Account permanent')}
			</div>
		)}
		</div>
	);
};

export default UserMenu;