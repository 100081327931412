import React, { useState, useEffect } from 'react';
import './css/popup.css';
import { Translator, resources } from './Translator';
import { CommonUtils } from './CommonUtils';
const translator = new Translator(resources);


const UserDataInputPopup = ({ fm, onClose }) => {
	const t = (key) => translator.t(key);
	const [name, setName] = useState('');
	const [color, setColor] = useState(Math.floor(Math.random() * CommonUtils.colors.length));
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showCloseButton, setShowshowCloseButton] = useState(true);

	useEffect(() => {
		getUserInfo();
	}, []);

	const getUserInfo = async () => {
		try {
			const userDocument = await fm.getUserDocument();
			if (userDocument) {
				setName(userDocument.name);
				setColor(userDocument.color);
				console.log('User document:', userDocument);
			} else {
				if(fm.auth && fm.auth.currentUser && fm.auth.currentUser.displayName) {
					setName(fm.auth.currentUser.displayName);
				}
				setColor(Math.floor(Math.random() * CommonUtils.colors.length));
				setShowshowCloseButton(false);
			}
		} catch (error) {
			console.error('Error fetching user document:', error);
		}
	}

	const handleSubmit = async () => {
		if(!name || name.length < 1) {
			alert(t('error_empty_name'));
			return;
		}

		setIsSubmitting(true); // 処理を開始する前に状態を変更
		const collectionPath = `users/`;
		const user = fm.auth.currentUser;
		const firedata = {
			name: name,
			createtime: new Date(),
			updatetime: new Date(),
			color: color, // RGB値を16進数文字列に変換
		};
		try {
			await fm.setDocument(collectionPath, user.uid, firedata);
			console.log('Document successfully written!');
		} catch (error) {
			console.error('Failed to write document:', error);
		}
		setIsSubmitting(false); // 処理が完了したら状態を変更
		onClose();
	};

	const rgbColor = CommonUtils.colors[color];
	return (
		<div className="popup">
		{showCloseButton && 
			<span className="close_button" onClick={onClose}>&times;</span>
		}
		<div className="popup-inner">
			<h2>{t('input_userinfo')}</h2>
			<label>
			{t('input_name')}:
			<input type="text" value={name} onChange={(e) => setName(e.target.value)} disabled={isSubmitting} />
			</label>
			<label>
				{t('input_color')}:
				<div className="color-dropdown" style={{width:'150px'}}>
					<button disabled={isSubmitting} className="color-dropdown-button">
					<span
						style={{
						backgroundColor: `#${rgbColor.toString(16).padStart(6, '0')}`,
						display: 'inline-block',
						width: '20px',
						height: '20px',
						marginRight: '10px',
						verticalAlign: 'middle',
						}}
					></span>
					#{rgbColor.toString(16).padStart(6, '0')}
					</button>
					<div className="color-dropdown-content">
					{CommonUtils.colors.map((colorValue,index) => (
						<div
						key={colorValue}
						className="color-option"
						onClick={() => setColor(index)}
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '5px',
							cursor: 'pointer',
						}}
						>
						<span
							style={{
							backgroundColor: `#${colorValue.toString(16).padStart(6, '0')}`,
							display: 'inline-block',
							width: '20px',
							height: '20px',
							marginRight: '10px',
							verticalAlign: 'middle',
							}}
						></span>
						#{colorValue.toString(16).padStart(6, '0')}
						</div>
					))}
					</div>
				</div>
			</label>

			<button className='button_y' id="buttonMakeProject" onClick={handleSubmit} disabled={isSubmitting}>
			{isSubmitting ? t('Processing...') : t('Save')}
			</button>
		</div>
		</div>
	);
};

export default UserDataInputPopup;
