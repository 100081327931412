import React from 'react';
import './css/popup.css';
import { Translator, resources } from './Translator';

const translator = new Translator(resources);

const LoginPopup = ({ onAnonymousLogin, onGoogleLogin, onClose }) => {
	const t = (key) => translator.t(key);

	const handleAnonymousLogin = () => {
		onAnonymousLogin();
		onClose();
	};

	const handleGoogleLogin = () => {
		onGoogleLogin();
		onClose();
	};

	return (
		<div className="popup"  style={{ maxWidth: '480px',minWidth: '240px'}}>
			<div className="popup-inner">
				<span className="close_button" onClick={onClose}>&times;</span>
				<h2>{t('Login')}</h2>
				<div className="left-aligned-text">{t('Login message')}</div>
				<button className="button_b" onClick={handleAnonymousLogin}>{t('Anonymous login')}</button>
				<button className="button_b" onClick={handleGoogleLogin}>{t('Login on Google')}</button>
				<div><a href='https://collabomap.com/privacy-policy.html' target='blank' className="text_link" >{t('Terms and privacy policy')}</a></div>
			</div>
		</div>
	);
};

export default LoginPopup;
