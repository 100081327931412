import maplibregl from 'maplibre-gl';

class CustomMap {
    constructor(map, imageData, corners) {
        this.map = map;
        this.imageData = imageData;
        this.corners = corners;
        this.draggingCornerIndex = null;
        this.addImageLayer();
        this.addCornerMarkers();
    }

    addImageLayer() {
        const id = `custom-map-${Date.now()}`;
        this.imageLayerId = id;
        this.map.addSource(id, {
            type: 'image',
            url: this.imageData,
            coordinates: this.corners
        });

        this.map.addLayer({
            id: id,
            source: id,
            type: 'raster',
            paint: {
                'raster-opacity': 0.85
            }
        });
    }

    createTransparentMarkerElement() {
        const markerElement = document.createElement('div');
        markerElement.style.width = '10px';
        markerElement.style.height = '10px';
        markerElement.style.backgroundColor = 'rgba(255, 255, 255, 0)'; // 完全に透明
        markerElement.style.border = '2px solid rgba(0, 0, 0, 0.1)'; // 半透明の枠線
        markerElement.style.borderRadius = '50%'; // 円形にする
        return markerElement;
    }

    addCornerMarkers() {
        this.cornerMarkers = this.corners.map((corner, index) => {
            const markerElement = this.createTransparentMarkerElement();
            const marker = new maplibregl.Marker({ element: markerElement, draggable: true })
                .setLngLat(corner)
                .addTo(this.map);

            marker.on('dragstart', () => {
                this.setLayerOpacity(0.5);
            });

            marker.on('drag', () => {
                this.updateCornerPosition(marker.getLngLat(), index);
            });

            marker.on('dragend', () => {
                this.setLayerOpacity(0.85);
            });

            return marker;
        });
    }

    setLayerOpacity(opacity) {
        this.map.setPaintProperty(this.imageLayerId, 'raster-opacity', opacity);
    }

    updateCornerPosition(newLngLat, index) {
        this.corners[index] = [newLngLat.lng, newLngLat.lat];
        this.map.getSource(this.imageLayerId).setCoordinates(this.corners);
    }
}

export default CustomMap;
