import React from 'react';

const LoadingPopup = ({ isVisible,message }) => {
	if (!isVisible) return null;

	return (
		<div style={styles.loadingPopup}>
		<div style={styles.loadingSpinner}></div>
		<p style={styles.loadingText}>{message}</p>
		</div>
	);
};

const styles = {
	loadingPopup: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 9999,
	},
	loadingSpinner: {
		width: '50px',
		height: '50px',
		border: '5px solid #f3f3f3',
		borderTop: '5px solid #3498db',
		borderRadius: '50%',
		animation: 'spin 1s linear infinite',
	},
	loadingText: {
		marginTop: '20px',
		color: 'white',
		fontSize: '18px',
	},
	'@keyframes spin': {
		'0%': { transform: 'rotate(0deg)' },
		'100%': { transform: 'rotate(360deg)' },
	},
};

// CSSアニメーションをhead要素に追加
const styleElement = document.createElement('style');
styleElement.textContent = `
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
`;
document.head.appendChild(styleElement);

export default LoadingPopup;