import React, { useEffect, useState, useRef } from 'react';
import { useParams,useNavigate  } from 'react-router-dom';
import './css/App.css';
import AddProjectPopup from './AddProjectPopup';
import ExistingProjectPopup from './ExistingProjectPopup';
import MapManager from './MapManager';
import { Translator, resources } from './Translator';
import ConfirmDialog from './ConfirmDialog';
import LoginPopup from './LoginPopup';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import UserSettingsPopup from './UserSettingsPopup'; 
import UserMenu from './UserMenu';
import UserDataInputPopup from './UserDataInputPopup';
import { CommonUtils } from './CommonUtils';

const DynamicMetaTags = ({ imageUrl, title, description }) => {
	return (
		<Helmet>
		<meta name="twitter:card" content="summary_large_image" />
		<meta name="twitter:image" content={imageUrl} />
		<meta property="og:image" content={imageUrl} />
		{title && <meta name="twitter:title" content={title} />}
		{title && <meta property="og:title" content={title} />}
		{description && <meta name="twitter:description" content={description} />}
		{description && <meta property="og:description" content={description} />}
		</Helmet>
	);
};


const translator = new Translator(resources);

const MapScreen = ({fm , protruding}) => {
    const {idAtUrl } = useParams();
    const [_protruding, setProtruding] = useState(protruding);

    const [uuid, setUuid] = useState('');
    const [owneruid, setOwneruid] = useState('');
    const [user, setUser] = useState(null);
    const [userDoc, setUserDoc] = useState(null);
    const [isSignedIn, setIsSignedIn] = useState(0);
    const [showAddProjectPopup, setShowAddProjectPopup] = useState(false);
    const [showExistingProjectPopup, setShowExistingProjectPopup] = useState(false);
    const [myName, setMyName] = useState([]);
    const [myColor, setMyColor] = useState([]);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [currentProject, setCurrentProject] = useState(null);

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 480);

    const [showProjectInfoDialog, setShowProjectInfoDialog] = useState(false);
	const [showUserSettingsPopup, setShowUserSettingsPopup] = useState(false);
	const [userSettings, setUserSettings] = useState({});
	const [showUserMenu, setShowUserMenu] = useState(false);
	const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
	const [showUserDataInputPopup, setShowUserDataInputPopup] = useState(false);


	const handleIconClick = (event) => {
		if (showUserMenu) {
			setShowUserMenu(false);
		} else {
			const rect = event.target.getBoundingClientRect();
			setMenuPosition({
				top: rect.bottom + window.scrollY,
				left: rect.left + window.scrollX,
			});
			setShowUserMenu(true);
		}
	};

	const handleCloseUserMenu = () => {
		setShowUserMenu(false);
	};
	const handleUserSettings = () => {
		setShowUserSettingsPopup(true);
		setShowUserMenu(false);
	};


	const handleAccountPermanent = async () => {
		try {
			await fm.linkAnonymousAccountWithGoogle();
			console.log('Succeeded Account permanent');
			toast.info(t('Succeeded Account permanent')); 
		} catch (error) {
			console.error('Failed to make account permanent:', error);
			toast.info(t('Error Account permanent') + error.message); 
			
		}

		console.log('Account permanence requested');
		setShowUserMenu(false);
	};

	const handleCloseUserSettings = () => {
		setShowUserSettingsPopup(false);
	};
	const handleSaveUserSettings = () => {
		getUserSettings();
	};

	const getUserSettings = async () => {
		try {
			const settings = await fm.getUserDocument();
			setUserSettings(settings);
			setMyName(settings.name);
			setMyColor(settings.color);
		} catch (error) {
			console.error('Error fetching user settings:', error);
		}
	};

	useEffect(() => {
		if (isSignedIn) {
			getUserSettings();
		}
	}, [isSignedIn]);

    // 画面幅を監視する新しいuseEffect
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

	let startLogin = false;
	const [confirmDialog, setConfirmDialog] = useState({
		message: '',
		data: null,
		onConfirm: () => {},
		onCancel: () => {}
	});

    const myMapRef = useRef();

    // URLからuuidとowneruidを分割して取得
    useEffect(() => {
        const [extractedUuid, extractedOwneruid] = idAtUrl.split('_');
        setUuid(extractedUuid);
        setOwneruid(extractedOwneruid);
    }, [idAtUrl]);

    // ユーザーの認証状態を監視
    useEffect(() => {
        const unregisterAuthObserver = fm.auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
//				if (myMapRef && myMapRef.current) myMapRef.current.setScreeSize();
                getUserInfo(user);

            } else {
 //               if (myMapRef && myMapRef.current) myMapRef.current.setScreeSize();
                setIsSignedIn(0);
                setUser(null);
            }

			if (myMapRef && myMapRef.current) {
				myMapRef.current.updateUI();
			}
        });

        // Drag and drop event listeners
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('drop', handleDrop);

        return () => {
            unregisterAuthObserver();
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('drop', handleDrop);
        };
    }, []);

	//画面サイズ調整
	useEffect(() => {
		let innerHeight = window.visualViewport ? Math.floor(window.visualViewport.height) : Math.floor(document.documentElement.clientHeight);
		const innerHeightDifference = document.documentElement.scrollHeight - innerHeight;
//		toast.info(innerHeight);
		setProtruding(innerHeightDifference);
	}, []);

    // user と owneruid の変更を監視して isSignedIn を更新
    useEffect(() => {
        if (user && owneruid) {
            if (owneruid === user.uid) {
                setIsSignedIn(2);
            } else {
                setIsSignedIn(1);
            }
			setTimeout(() => {//ズラさないとUIが更新されないので300ミリ秒待つことにした
				if (myMapRef && myMapRef.current) {
					myMapRef.current.updateUI();
				}
			}, 300);
        }
		else {
			setIsSignedIn(0);
		}
    }, [user, owneruid]);
	
	const getUserInfo = async (user) => {
		try {
			const userDocument = await fm.getUserDocument();
			if (userDocument) {
				setUserSettings(userDocument);
				setUserDoc(userDocument);
				setMyName(userDocument.name);
				setMyColor(userDocument.color);

				fm.setNameAndColor(userDocument.name,userDocument.color);


				var photoURL = user.photoURL;
				fm.checkIconfileAndSave(user.uid, photoURL,userDocument.name,userDocument.color,'iconImage');

				console.log('User document:', userDocument);
			}
			else {
				const t = (key) => translator.t(key);
				CommonUtils.makeCircleIcon(null, t('Anonymous'), 100, 100, 3, CommonUtils.numberToHexColor(CommonUtils.colors[0]), (dataURL) => {
					document.getElementById('iconImage').src = dataURL;
				});
				setShowUserDataInputPopup(true);
			}
		} catch (error) {
			console.error('Error fetching user document:', error);
		}
	}

	/*const getProjectInfo = async (owneruid,uuid) => {
		try {
			const projectData = await fm.getProjectDocument(owneruid,uuid);
			if (projectData) {
				console.log('Project data:', projectData);
			}
			else {
			}
		} catch (error) {
			console.error('Error fetching Project data:', error);
		}
	}*/


	const handleAnonymousLogin = async () => {
		startLogin = false;
		try {
			const user = await fm.signInAnonymously();
			setUser(user);
			toast.info(t('Signed in')); 
		} catch (error) {
			console.error('Anonymous login failed:', error);
		}
	};

	const handleGoogleLogin = async () => {
		startLogin = false;
		try {
			const user = await fm.signInWithGoogle();
			setUser(user);
			toast.info(t('Signed in')); 
		} catch (error) {
			console.error('Google login failed:', error);
		}
	};


	const handleSignIn = () => {
		startLogin = true;
		setShowLoginPopup(true);
	};

	const handleSignOut = () => {
		showConfirm(
			fm.auth.currentUser.isAnonymous ? t('confirm_Logout_Anonymous') : t('confirm_Logout'),
			null,
			async () => {
				try {
					await fm.signOut();
					setUser(null);
					setShowConfirmDialog(false);
					toast.info(t('Signed out'));
				} catch (error) {
					console.error('Sign-out failed:', error);
				}
			},
			() => {
				setShowConfirmDialog(false);
			}
		);
	};


	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const files = e.dataTransfer.files;
		if (files && files.length > 0) {
//			const fileTypes = ['application/gpx+xml', 'application/vnd.google-earth.kml+xml', 'application/zip', 'application/vnd.geo+json', 'image/jpeg', 'image/png', 'image/gif'];
			if(files && files.length > 0) handleFileUpload({ target: { files: files } });

/*			Array.from(files).forEach((file) => {
				if (fileTypes.includes(file.type) || file.name.endsWith('.gpx') || file.name.endsWith('.kml') || file.name.endsWith('.zip') || file.name.endsWith('.geojson')) {
				}
			});*/
		}
	};

	const handleFileUpload = (event) => {
		if (myMapRef.current) {
			myMapRef.current.handleFileUpload(event);
		}
	};

	const handleAddProject = (event) => {
		setShowAddProjectPopup(true);
	};
	const handlePastExistingCollabo = (event) => {
		setShowExistingProjectPopup(true);
	};

	const navigate = useNavigate();
	const handleGoBack = (event) => {
		navigate(-1);
//		window.location.href = "/";
	};
	const handleToTopPage = (event) => {
		window.location.href = "/top";
	};

	const handleExistingCollaboClick = (id) => {
		alert(id);
		setShowExistingProjectPopup(false);
	};

	const t = (key) => translator.t(key);

    // signoutButton の透明度を設定するためのスタイル
    const signoutButtonStyle = {
        opacity: isSignedIn === 0 ? '0.5' : '1'
    };
	const signinButtonStyle = {
        opacity: isSignedIn === 0 ? '1' : '0.5'
    };
//					<span id="projectTitle">{this.state.projectTitle}</span>_<span id="ownrerName">{this.state.ownerName}</span>

    const updateProjectInfo = (projectData) => {
		setCurrentProject(projectData);

    };


	const showConfirm = (message, data, onConfirm, onCancel) => {
		setShowConfirmDialog(true);
		setConfirmDialog({ message, data, onConfirm, onCancel });
	};

    const handleProjectInfo = () => {
        if (currentProject) {
            setShowProjectInfoDialog(true);
        }
    };

	const formatMemo = (memo) => {
		if (!memo) return "...";
		return memo.split('\n').map((line, index) => (
			<React.Fragment key={index}>
				{line}
				{index < memo.split('\n').length - 1 && <br />}
			</React.Fragment>
		));
	};

	return (


		<div className="App-body-map" style={{height:`calc(100vh - ${_protruding}px`}}>
			<div id="header" className="header_container">
				<div className="header_left">
					<div className="header_cell" onClick={handleProjectInfo}>
					</div>
					{currentProject && (
						<div className="header_cell" onClick={handleProjectInfo}>
							<span id="projectTitle">{currentProject.title}</span>
							<span id="ownerName" style={{fontSize:'0.8em',marginLeft:'20px'}}>[{currentProject.ownername}]</span>
						</div>
					)}
				</div>
			</div>
			<MapManager 
				ref={myMapRef}
				fm={fm}
				isSignedIn={isSignedIn}
				uuid={uuid}
				owneruid={owneruid}
				myName={myName}
				userDoc={userDoc}
				myColor={myColor}
				user={user}
				protruding = {_protruding}
				style={{ zIndex: 1 }}
				updateProjectInfo={updateProjectInfo}
				userSettings={userSettings}
			/>
			{isSignedIn === 0 && (
			<>
				<img onClick={handleToTopPage} 
					id="logoImage" 
					alt='Collabomap logo' 
					src={isWideScreen ? "/img/logo_w.png" : "/img/logo_pin.png"} 
					style={{
						position: 'absolute', 
						left: '36px', 
						top: '-2px',
					}}
				/>
				<img id="signinButton" className="toolButton_w" src="/img/icon_login.png" onClick={handleSignIn} alt={t('sign_in')} style={signinButtonStyle}></img>
			</>
			)}

			{isSignedIn !== 0 && (
			<>
                <img onClick={handleToTopPage} 
                    id="logoImage" 
                    alt='Collabomap logo' 
                    src={isWideScreen ? "/img/logo_w.png" : "/img/logo_pin.png"} 
                    style={{
                        position: 'absolute', 
                        left: '36px', 
                        top: '-2px',
                    }}
                />
				<img id="iconImage" alt={myName} className="rounded-image" style={{position: 'absolute',right: '45px',top: '2px'}} onClick={handleIconClick}></img>

				{showUserMenu && (
					<UserMenu
						fm={fm}
						position={menuPosition}
						onClose={handleCloseUserMenu}
						onUserSettings={handleUserSettings}
						onAccountPermanent={handleAccountPermanent}
					/>
				)}
				<img id="signoutButton" className="toolButton_w" src="/img/icon_logout.png" onClick={handleSignOut} alt={t('sign_out')} style={signoutButtonStyle}></img>
				<img id="goBackButton" style={{zIndex:80}} className="toolButton_w" src="/img/icon_arrow_back.png" onClick={handleGoBack} alt={t('goBack')}></img>
			</>
			)}

			{showAddProjectPopup && 
				<AddProjectPopup 
					fm={fm}
					onClose={() => setShowAddProjectPopup(false)}
				/>
			}
			{showExistingProjectPopup && 
				<ExistingProjectPopup 
					onClose={() => setShowExistingProjectPopup(false)}
					onItemClick={handleExistingCollaboClick}
				/>
			}
			{showConfirmDialog && (
				<ConfirmDialog
					message={confirmDialog.message}
					data = {confirmDialog.data}
					okTitle={'OK'}
					cancelTitle={t('Cancel')}
					onConfirm={confirmDialog.onConfirm}
					onCancel={confirmDialog.onCancel}
				/>
			)}

			{showLoginPopup && (
				<LoginPopup
					onAnonymousLogin={handleAnonymousLogin}
					onGoogleLogin={handleGoogleLogin}
					onClose={() => setShowLoginPopup(false)}
				/>
			)}

			{showProjectInfoDialog && (
				<ConfirmDialog
					message={
						<div>
							<h2>{currentProject.title}</h2>
							<div>{formatMemo(currentProject.memo)}</div>
							<p>{currentProject.tags ? currentProject.tags.map(tag => `#${tag}`).join(' ') : ''|| "..."}</p>
						</div>
					}
					okTitle={t('Close')}
					onConfirm={() => setShowProjectInfoDialog(false)}
				/>
			)}
			{showUserSettingsPopup && (
				<UserSettingsPopup
					fm={fm}
					onClose={handleCloseUserSettings}
					initialSettings={userSettings}
					onSave={handleSaveUserSettings}
				/>
			)}
			{showUserDataInputPopup && (
				<UserDataInputPopup
					fm={fm}
					onClose={() => {
						setShowUserDataInputPopup(false);
						getUserInfo(fm.auth.currentUser);
					}}
				/>
			)}
		</div>
	);
};
/*
			<input type="file" accept=".*" onChange={handleFileUpload} style={{ position: 'absolute', top: 10, right: 180, zIndex: 100 }} />
			<button onClick={handleAddProject} style={{ position: 'absolute', top: 10, right: 600, zIndex: 100 }}>
				{t('new_projects')}
			</button>
			<button onClick={handlePastExistingCollabo} style={{ position: 'absolute', top: 10, right: 450, zIndex: 100 }}>
				{t('exist_projects')}
			</button>

*/
export default MapScreen;
